import React from "react";
import {Navigate, NavigateFunction} from "react-router-dom";
import {
  Logout,
  SetAllCategories,
  SetAllKnowledgeBases,
  SetAllQuestions,
  SetAssistantSettings,
  SetCurrentKnowledgeBase,
  SetCustomer,
  SetSettings,
  SetValidate,
} from "../reducers/store";
import {GetCategories, GetQuestions} from "../services";
import {SuspenseWrap} from "./Header";
import {
  GetKnowledgeBases,
  GetKBSettings,
  ValidateToken,
  GetSiteSettings,
  GetReleases,
  GetCustomerBySiteId,
  GetAssistantSettings,
} from "src/services/backend";
import {AUTH_TOKEN_KEY, USER_KEY} from "src/config/config";
import {getLocalStorageItem} from "src/utilities/cookie";
import {withParams, withRouter} from "src/utilities/hooks";
import {FlattenCategories} from "src/utilities/utils";
import {User} from "src/interfaces/user";
import {I18N} from "src/interfaces";
import {withTranslation} from "react-i18next";
import {Release} from "src/interfaces/changelog";

interface AuthorizedRouteProps extends I18N {
  container: React.ReactElement<DefaultProps>;
  navigate: NavigateFunction;
  params: {
    uniqueId?: string;
  };
}

interface AuthorizedRouteState {
  inProgress: boolean;
  assistantSettingsLoading: boolean;
  releases: Release[];
}

interface DefaultProps {
  inProgress: boolean;
  assistantSettingsLoading: boolean;
  releases: Release[];
}

class AuthorizedRoute extends React.Component<AuthorizedRouteProps, AuthorizedRouteState> {
  constructor(props) {
    super(props);
    this.state = {
      inProgress: true,
      assistantSettingsLoading: true,
      releases: [],
    };
  }

  async validateToken() {
    try {
      const response = await ValidateToken();
      SetValidate(response.data);
    } catch (error) {
      Logout();
    }
  }

  async componentDidMount() {
    await this.validateToken();
    await this.getData();
    this.setState({inProgress: false});
  }

  render() {
    return (
      <SuspenseWrap
        container={
          this.isLoggedIn() ? (
            React.cloneElement<DefaultProps>(this.props.container, {
              inProgress: this.state.inProgress,
              assistantSettingsLoading: this.state.assistantSettingsLoading,
              releases: this.state.releases,
            })
          ) : (
            <Navigate to={"/login"} />
          )
        }
      />
    );
  }

  isLoggedIn(): boolean {
    return getLocalStorageItem(AUTH_TOKEN_KEY) ? true : false;
  }

  async getData() {
    const KbUniqueId = this.props.params?.uniqueId;
    const knowledgeBases = await this.getKnowledgeBases();
    const currentKnowledgeBase = KbUniqueId
      ? knowledgeBases?.find((kb) => kb.uniqueId === KbUniqueId)
      : knowledgeBases?.find((kb) => !kb.deleted);
    // TODO: Set in localstorage?

    if (currentKnowledgeBase?.deleted || !currentKnowledgeBase) {
      this.props.navigate("/admin/dashboard");
      this.setState({inProgress: false});
      return;
    }

    SetCurrentKnowledgeBase(currentKnowledgeBase);

    return Promise.all([
      this.getCategories(currentKnowledgeBase.id),
      this.getQuestions(currentKnowledgeBase.id),
      this.getSettings(currentKnowledgeBase.id),
      this.getSiteSettings(currentKnowledgeBase.id),
      this.getReleases(),
    ]);
  }

  async getKnowledgeBases() {
    const response = await GetKnowledgeBases();
    SetAllKnowledgeBases(response.data);
    return response.data;
  }

  async getQuestions(knowledgeBaseId: number) {
    const response = await GetQuestions(knowledgeBaseId);
    SetAllQuestions(response);
  }

  async getCategories(knowledgeBaseId: number) {
    const response = await GetCategories(knowledgeBaseId);
    const allCategories = FlattenCategories(response);

    SetAllCategories(allCategories);
  }

  async getSettings(knowledgeBaseId: number) {
    const response = await GetKBSettings(knowledgeBaseId);
    SetSettings(response);
  }

  async getAssistantSettings(knowledgeBaseId: number) {
    const response = await GetAssistantSettings(knowledgeBaseId);
    SetAssistantSettings(response.data);
    this.setState({assistantSettingsLoading: false});
  }

  async getReleases() {
    const response = await GetReleases();
    this.setState({releases: response.data?.reverse() || []});
  }

  async getSiteSettings(knowledgeBaseId: number) {
    const siteId = getLocalStorageItem<User>(USER_KEY, "{}", true)?.siteId;
    const response = await GetSiteSettings(siteId);
    const customer = await GetCustomerBySiteId(siteId);
    if (customer.data.assistantEnabled) {
      this.getAssistantSettings(knowledgeBaseId);
    }
    SetCustomer(customer.data);
    this.props.i18n.changeLanguage(response.settings.language);
  }
}

export default withTranslation()(withRouter(withParams(AuthorizedRoute)));
