import React, {CSSProperties} from "react";
import feather from "feather-icons";

interface IconProps {
  name: string;
  iconSize?: string;
  style?: CSSProperties;
  className?: string;
  onClick?: Function;
  color?: string;
  tooltip?: string;
}

//https://feathericons.com/
const Icon: React.FC<IconProps> = ({name, iconSize = "16px", color, className, onClick, tooltip, style}) => {
  const svgString = feather.icons[name]?.toSvg({
    width: iconSize,
    height: iconSize,
    color: color || "currentColor",
  });

  return (
    <span
      title={tooltip || ""}
      className={className || ""}
      style={style}
      onClick={(e) => onClick && onClick()}
      dangerouslySetInnerHTML={{__html: svgString || ""}}
    />
  );
};

export default Icon;
