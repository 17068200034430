import {LinearProgress} from "@mui/material";
import {Component, Suspense} from "react";
import {connect} from "react-redux";
import {SETTINGS_KEY} from "src/config/config";
import {getLocalStorageItem} from "src/utilities/cookie";
import {SettingsReducerPayload, UISettings} from "../interfaces/settings";
import Icon from "./Icon";
import {Button} from "./ui/button";
import {ThemeToggle} from "./ThemeToggle";
import Changelog from "./Changelog";
import {Release} from "src/interfaces/changelog";
import {store} from "../App";
import {Action} from "../interfaces/redux";

interface HeadingProps {
  title: string;
  iconName: string;
  releases: Release[];
  sidebarExpanded?: boolean;
  theme: "light" | "dark";
}

const HeadingComponent = (props: HeadingProps) => {
  const handleStretchClick = () => {
    store.dispatch({
      type: Action.SETTINGS,
      payload: {
        sidebarExpanded: !props.sidebarExpanded,
      },
    });
  };

  return (
    <div className="fixed inset-0 h-[95px] pl-[66px] w-full bg-card flex gap-8 items-center border-b z-20">
      <Button
        className="absolute bg-transparent border-none h-10 w-10 left-2 top-[27px] z-20 text-foreground"
        onClick={handleStretchClick}
      >
        <Icon name="menu" iconSize="30px" color="" />
      </Button>
      <div className="relative bottom-3">
        <span className="relative top-4 right-6">
          <Changelog releases={props.releases} />
        </span>
        <span className="absolute top-[50px] left-[20px]">
          <ThemeToggle theme={props.theme} />
        </span>
        <img src="/motion.png" alt="Connectel logo" className="h-10" />
      </div>
      <div className={`flex gap-4 items-center ${props.sidebarExpanded ? "pl-40" : ""} transition-all duration-300`}>
        <Icon name={props.iconName} iconSize="30px" />
        <h1 className="text-3xl ">{props.title}</h1>
      </div>
    </div>
  );
};

const Container = (props) => {
  return <div className="main-container">{props.children}</div>;
};

const ContentContainer = (props) => {
  const expanded = getLocalStorageItem<SettingsReducerPayload>(SETTINGS_KEY, "{}", true)?.sidebarExpanded;
  return (
    <div className={`h-screen ${!expanded ? "content-container-contracted" : "content-container"}`}>
      {props.children}
    </div>
  );
};

const SuspenseWrap = (props) => {
  return <Suspense fallback={<LinearProgress />}>{props.container}</Suspense>;
};

interface FloatingMenuProps {
  iconHandlers?: Record<string, (e) => void>;
}

class FloatingMenu extends Component<FloatingMenuProps, {}> {
  render() {
    return (
      <div className={`floating-container z-50`}>
        {Object.keys(this.props.iconHandlers).map((icon, i) => {
          return (
            <Button
              className="rounded-full h-20 w-20 shadow-2xl bg-primary"
              variant="default"
              onClick={(e) => this.props.iconHandlers[icon](e)}
              key={`floating-button-${i}`}
            >
              <Icon name={icon} iconSize="30px" color="white" />
            </Button>
          );
        })}
      </div>
    );
  }
}

function mapStateToProps(state: {settings: UISettings}) {
  return {
    sidebarExpanded: state.settings.sidebarExpanded,
    theme: state.settings.theme,
  };
}

const Heading = connect(mapStateToProps)(HeadingComponent);
const ContentBox = connect(mapStateToProps)(ContentContainer);

export {Heading, FloatingMenu, Container, ContentBox, SuspenseWrap};
