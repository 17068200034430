import {Accordion, AccordionContent, AccordionItem, AccordionTrigger} from "../ui/accordion";
import {Question} from "../../interfaces/questions";
import {useParams} from "react-router-dom";
import {Button} from "../ui/button";
import {modifyHtmlLinks} from "src/utilities/utils";
import {useQuestionAccordionFragment} from "src/utilities/hooks/useQuestionAccordionFragment";
import {FragmentMode, QuestionAccordionSettings} from "src/interfaces/fragments";
import {t} from "i18next";
import {UpdateQuestionViews} from "src/services/backend";
import {useIframeResize} from "src/utilities/hooks/useIframeResize";
import {useEffect} from "react";

interface QuestionAccordionProps {
  lang?: string;
  questions?: Question[];
  mode?: FragmentMode;
  onRemoveQuestion?: (question: Question) => void;
}

const QuestionAccordion = ({questions, mode, onRemoveQuestion, lang}: QuestionAccordionProps) => {
  const {uniqueId, fragmentId} = useParams();
  const isIframeMode = mode === FragmentMode.Iframe;
  const {
    knowledgeBaseId,
    settings,
    questions: fetchedQuestions,
    isLoading,
    error,
  } = useQuestionAccordionFragment(isIframeMode ? uniqueId : undefined, isIframeMode ? fragmentId : undefined);

  const questionsToRender = mode === FragmentMode.Settings ? questions : fetchedQuestions;

  const elementId = `questions-accordion-${fragmentId}`;
  const {sendHeightToParent} = useIframeResize(elementId);

  useEffect(() => {
    if (!isLoading && questionsToRender?.length > 0) {
      sendHeightToParent();
    }
  }, [isLoading, questionsToRender, sendHeightToParent]);

  if (isLoading && !questions) return <div className="text-center h-full w-full">{t("LOADING")}</div>;
  if (error)
    return (
      <div className="text-center h-full w-full">
        {t("ERROR")}: {error.message}
      </div>
    );

  const langToUse = mode === FragmentMode.Settings ? lang : settings?.lang;

  return (
    <div id={elementId}>
      {settings?.title && <h2 className="text-xl font-medium">{settings?.title}</h2>}
      <Accordion
        className="min-h-auto"
        type="single"
        collapsible
        onValueChange={(value) => {
          if (value && knowledgeBaseId) {
            const question = questionsToRender?.find((q) => q.id.toString() === value);
            if (question && mode === FragmentMode.Iframe) {
              UpdateQuestionViews(knowledgeBaseId, question.categoryId, question.id, question.langs[langToUse].id);
            }
          }
        }}
      >
        {questionsToRender &&
          questionsToRender
            .sort((a, b) => {
              const aViews = a.langs[langToUse]?.views || 0;
              const bViews = b.langs[langToUse]?.views || 0;
              return bViews - aViews;
            })
            .slice(
              0,
              mode === FragmentMode.Iframe ? (settings?.settings as QuestionAccordionSettings)?.limit : undefined,
            )
            .map((question) => {
              if (question) {
                const questionLang = question.langs[langToUse]?.question || `Question not translated`;
                const answerLang = question.langs[langToUse]?.answer || `Answer not translated`;
                return (
                  <AccordionItem value={question.id.toString()} key={question.id}>
                    <AccordionTrigger className="w-full ">
                      <span className="truncate" title={questionLang}>
                        {questionLang}
                      </span>
                    </AccordionTrigger>
                    <AccordionContent className="" onAnimationEnd={sendHeightToParent}>
                      <div
                        className="unreset"
                        dangerouslySetInnerHTML={{
                          __html: modifyHtmlLinks(answerLang),
                        }}
                      />
                      {onRemoveQuestion && (
                        <Button variant="ghost" size="sm" onClick={() => onRemoveQuestion(question)} className="mt-4">
                          {t("DELETE")}
                        </Button>
                      )}
                    </AccordionContent>
                  </AccordionItem>
                );
              }
            })}
      </Accordion>
    </div>
  );
};

export default QuestionAccordion;
