import {Component} from "react";
import {Release} from "src/interfaces/changelog";
import Icon from "./Icon";
import {withTranslation} from "react-i18next";

interface ChangelogProps {
  releases: Release[];
  t: (key: string) => string;
}

interface ChangelogState {
  showChangelog: boolean;
  showDetails: {[key: string]: boolean};
}

class Changelog extends Component<ChangelogProps, ChangelogState> {
  constructor(props: ChangelogProps) {
    super(props);
    this.state = {
      showChangelog: false,
      showDetails: {},
    };
  }

  render() {
    if (!this.props.releases || this.props.releases.length === 0) return null;
    return (
      <div className="flex items-center p-1 z-40">
        <button className="text-xs text-gray-500" onClick={() => this.toggleChangelog()}>
          {this.props.releases[0].version}
        </button>
        {this.state.showChangelog && this.renderChangelog()}
      </div>
    );
  }

  renderChangelog() {
    return (
      <>
        <div className="fixed inset-0 bg-black opacity-40 z-30" onClick={() => this.toggleChangelog()} />
        <div className="fixed top-0 left-0 h-screen w-96 shadow-2xl bg-card z-40">
          <div className="flex">
            <button
              className="text-sm text-foreground ml-auto p-2"
              onClick={() => this.toggleChangelog()}
              title={this.props.t("CLOSE")}
              aria-label={this.props.t("CLOSE")}
            >
              <Icon name="x" iconSize="20px" />
            </button>
          </div>
          <h3 className="text-lg font-bold ml-2">Changelog</h3>
          <div className="h-[calc(100%-67px)]">
            <ul className="flex flex-col gap-4 p-6 bg-card overflow-y-auto h-full">
              {this.getLatestReleases()}
              {this.getOlderReleases()}
            </ul>
          </div>
        </div>
      </>
    );
  }

  getLatestReleases() {
    return this.props.releases.slice(0, 5).map((release) => (
      <li className="flex flex-col gap-2" key={release.id}>
        <div className="flex gap-6">
          <p className="font-semibold">{release.createdAt.split(" ")[0]}</p>
          <p className="text-gray-500 text-sm">v{release.version}</p>
        </div>
        <ul className="flex flex-col gap-2 ml-4">
          {release.description.map((description) => this.getDescription(description))}
        </ul>
      </li>
    ));
  }

  getOlderReleases() {
    return this.props.releases.slice(5).map((release) => (
      <li className="flex flex-col gap-2" key={release.id}>
        <div className="flex gap-6">
          <p className="font-semibold">{release.createdAt.split(" ")[0]}</p>
          <p className="text-gray-500 text-sm">v{release.version}</p>
          <button className="text-xs text-gray-500 ml-auto" onClick={() => this.toggleDetails(release.id)}>
            {this.state.showDetails[release.id] ? (
              <Icon name="chevron-up" iconSize="20px" />
            ) : (
              <Icon name="chevron-down" iconSize="20px" />
            )}
          </button>
        </div>
        {this.state.showDetails[release.id] && (
          <ul className="flex flex-col gap-2 ml-4">
            {release.description.map((description) => this.getDescription(description))}
          </ul>
        )}
      </li>
    ));
  }

  getDescription(description) {
    return (
      <li
        key={description.text}
        className={`border-l-4 border-b pl-2 ${
          description.type === "feat" ? "border-l-green-500" : "border-l-red-500"
        }`}
      >
        <div className="flex gap-2">
          {this.getTag(description.type)} <span className="w-60">{description.text}</span>
        </div>
      </li>
    );
  }

  getTag(type: string) {
    let text;
    let bgColor;
    switch (type) {
      case "feat":
        text = "Feature";
        bgColor = "bg-green-200";
        break;
      case "fix":
        text = "Fix";
        bgColor = "bg-red-200";
        break;
      default:
        text = type;
        bgColor = "bg-gray-200";
    }

    return (
      <span className={`w-12 h-fit truncate text-xs text-gray-600 ${bgColor} px-2 py-1 rounded-md`} title={text}>
        {text}
      </span>
    );
  }

  toggleDetails(id: number) {
    this.setState((prevState) => ({
      showDetails: {...prevState.showDetails, [id]: !prevState.showDetails[id]},
    }));
  }

  toggleChangelog() {
    this.setState({showChangelog: !this.state.showChangelog});
  }
}

export default withTranslation()(Changelog);
