import {SETTINGS_KEY} from "src/config/config";
import {getLocalStorageItem, setLocalStorageItem} from "src/utilities/cookie";
import {Action} from "../interfaces/redux";
import {SettingsReducerPayload} from "../interfaces/settings";

const DefaultState = {
  sidebarExpanded: getLocalStorageItem<SettingsReducerPayload>(SETTINGS_KEY, "{}", true)?.sidebarExpanded,
  theme: getLocalStorageItem<SettingsReducerPayload>(SETTINGS_KEY, "{}", true)?.theme ?? ("light" as const),
  settings: null,
};

export default function SettingsReducer(
  state: SettingsReducerPayload = DefaultState,
  action: {type: Action; payload: Partial<SettingsReducerPayload>},
): SettingsReducerPayload {
  switch (action.type) {
    case Action.SETTINGS:
      const newState = {...state, ...action.payload};
      setLocalStorageItem(SETTINGS_KEY, JSON.stringify(newState));
      return newState;
    default:
      return state;
  }
}

export const SetTheme = (theme: "light" | "dark") => {
  return {
    type: Action.SETTINGS,
    payload: {theme},
  };
};
