import * as React from "react";
import {useEffect, useState} from "react";
import {useDispatch} from "react-redux";
import Icon from "./Icon";
import {Button} from "./ui/button";
import {SetTheme} from "../reducers/settings";

export function ThemeToggle(props: {theme: "light" | "dark"}) {
  const dispatch = useDispatch();
  const [isDark, setIsDark] = useState(props.theme === "dark");

  useEffect(() => {
    setIsDark(props.theme === "dark");
    if (props.theme === "dark") {
      document.documentElement.classList.add("dark");
    } else {
      document.documentElement.classList.remove("dark");
    }
  }, [props.theme]);

  const toggleTheme = () => {
    const newIsDark = !isDark;
    setIsDark(newIsDark);

    if (newIsDark) {
      document.documentElement.classList.add("dark");
      dispatch(SetTheme("dark"));
    } else {
      document.documentElement.classList.remove("dark");
      dispatch(SetTheme("light"));
    }
  };

  return (
    <Button variant="ghost" onClick={toggleTheme} className={`border-none bg-transparent rounded-full p-0 h-4 w-4`}>
      <Icon name={isDark ? "sun" : "moon"} iconSize="16px" color="gray" className="transition-all" />
      <span className="sr-only">Toggle theme</span>
    </Button>
  );
}
